html,
body {
  margin: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

.container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-left: 300px
}

input[type="file"] {
  position: absolute;
  z-index: -1;
  top: 15px;
  left: 20px;
  font-size: 17px;
  color: #b8b8b8;
}

.button-wrap {
  position: relative;
}

.button {
  display: inline-block;
  background-color: #1d6355;
  border-radius: 10px;
  border: 4px double #cccccc;
  color: #ffffff;
  text-align: center;
  font-size: 20px;
  padding: 8px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.button:hover {
  background-color: #00ab97;
}

.loading-button {
  position: relative;
}

.loading-button.loading::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid transparent;
  border-top: 4px solid white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}
